import React from 'react'

import Main from '../../misc/Main'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'

interface Props {
  children: React.ReactNode
}

const LandingBlackLayout: React.FC<Props> = ({ children }) => (
  <Main
    className="bg-black pb-3"
    style={{
      backgroundImage: 'linear-gradient(145deg, #2a3141, #1a6aac',
      backgroundAttachment: 'fixed',
    }}
  >
    <style>
      {/* Because just putting it on main means the overscroll on Mac/iPhone is still white */}
      {'body { background-color: #2a3141; }'}
    </style>
    <SentryErrorBoundary variant="full-screen">{children}</SentryErrorBoundary>
  </Main>
)

export default LandingBlackLayout
