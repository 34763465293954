import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router'

import { useHomeRoute } from '../../hooks/useHomeRoute'

export interface InvalidOrExpiredCardProps {
  title?: React.ReactNode
  content?: React.ReactNode
}

const InvalidOrExpiredCard: React.FC<InvalidOrExpiredCardProps> = ({
  title,
  content,
}) => {
  const homeRoute = useHomeRoute()

  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title>
          <h1>{title || 'Invalid link'}</h1>
        </Card.Title>

        {content || <p>This link is either invalid or has been revoked.</p>}

        <Link to={homeRoute}>
          <Button variant="primary" size="lg">
            Return to home
          </Button>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default InvalidOrExpiredCard
