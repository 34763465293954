export function isDevelopment(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isStaging(): boolean {
  return window.location.host === 'staging.rq.app'
}

export function isFeature(): boolean {
  return window.location.host === 'feature.rq.app'
}

export function isProduction(): boolean {
  return window.location.host === 'refer.rq.app'
}

export function isHotfix(): boolean {
  return window.location.host === 'hotfix.rq.app'
}

export function isDocker(): boolean {
  const hostUrl = `${window.location.protocol}//${window.location.host}`

  return hostUrl.includes('host.docker.internal')
}

export function isNotProduction(): boolean {
  return !isProduction()
}

export function isReferralTargetSearchBoxEnabled() {
  return import.meta.env.VITE_ENABLE_REFERRAL_TARGET_SEARCHBOX === 'true'
}
