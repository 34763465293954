import './NylasScheduler.scss'

import { NylasSchedulerBookingParticipant, ThemeConfig } from '@nylas/core'
import { NylasScheduling } from '@nylas/react'
import React from 'react'

import { currentUrl } from '../../../lib/helpers/routeHelpers'
import sentryService from '../../../lib/services/sentryService'
import NylasSchedulerError from './NylasSchedulerError'

interface Props {
  schedulerConfigId: string
  className?: string
  primaryParticipant: NylasSchedulerBookingParticipant
  onBookingComplete: (completedBooking: BookingDetails) => void
}

export interface BookingDetails {
  startTime: Date
  endTime: Date
}

const NylasScheduler: React.FC<Props> = ({
  schedulerConfigId,
  className,
  primaryParticipant,
  onBookingComplete,
}) => {
  if (!schedulerConfigId) {
    return <NylasSchedulerError />
  }

  return (
    <>
      {/* @see https://tinyurl.com/nylas-react */}
      <NylasScheduling
        configurationId={schedulerConfigId}
        mode="app"
        schedulerApiUrl="https://api.eu.nylas.com"
        bookingInfo={{ primaryParticipant }}
        themeConfig={THEME_CONFIG}
        onNylasSchedulerError={(error) => {
          sentryService.captureMessage({
            message: `Nylas scheduler error on page: ${currentUrl()}`,
            extra: {
              clientDetails: primaryParticipant,
              error: error.detail.notification,
            },
          })
        }}
        className={className}
        nylasBranding={false}
        enableUserFeedback={false}
        defaultSchedulerState={{ showBookingForm: false }}
        // @ts-expect-error: Nylas types are incorrect
        localization={{
          en: {
            // We skip Nylas' default confirmation step so change the default
            // button text from 'Next' to 'Schedule meeting'
            nextButton: 'Schedule meeting',
          },
        }}
        eventOverrides={{
          timeslotConfirmed: async (event, connector) => {
            event.preventDefault()

            if (!connector) {
              sentryService.captureMessage({
                message: 'Expected connector to be defined',
              })

              return
            }

            await connector.scheduler.bookTimeslot({ primaryParticipant })

            onBookingComplete({
              startTime: event.detail.start_time,
              endTime: event.detail.end_time,
            })
          },
        }}
      />
    </>
  )
}

// Make the scheduler look as RQ-like as possible
const THEME_CONFIG: ThemeConfig = {
  '--nylas-primary': 'var(--bs-primary)',
  '--nylas-info': 'var(--bs-info)',
  '--nylas-success': 'var(--bs-success)',
  '--nylas-warning': 'var(--bs-warning)',
  '--nylas-error': 'var(--bs-error)',
  '--nylas-base-100': 'var(--bs-gray-100)',
  '--nylas-base-200': 'var(--bs-gray-200)',
  '--nylas-base-300': 'var(--bs-gray-300)',
  '--nylas-base-400': 'var(--bs-gray-400)',
  '--nylas-base-500': 'var(--bs-gray-500)',
  '--nylas-base-600': 'var(--bs-gray-600)',
  '--nylas-base-700': 'var(--bs-gray-700)',
  '--nylas-base-800': 'var(--bs-gray-800)',
  '--nylas-base-900': 'var(--bs-gray-900)',
  '--nylas-font-family': 'var(--bs-body-font-family)',
  '--nylas-font-size': 'var(--bs-body-font-size)',
  '--nylas-border-radius': 'calc(var(--bs-border-radius) * .5)',
  '--nylas-border-radius-2x': 'calc(var(--bs-border-radius) * 1)',
  '--nylas-border-radius-3x': 'calc(var(--bs-border-radius) * 1.5)',
}

export default NylasScheduler
